import * as React from "react"

import { GridContainer } from "../common/layout/Containers"
import Card from "../ui/Card"

const Posts = ({ posts, rootUrl, fit, minimal }) => {
  return (
    <GridContainer fit={fit}>
      {posts.map((item) => (
        <Card
          gatsbyImage={item.heroImage}
          key={item.id}
          title={item.title}
          category={item.categories[0]}
          description={item.description.description}
          readTime={item.readTime}
          publishDate={item.publishDate}
          url={`${rootUrl}${item.slug}/`}
          minimal={minimal}
        />
      ))}
    </GridContainer>
  )
}

export default Posts
