import * as React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Clock } from "react-feather"

const Card = ({
  gatsbyImage,
  title,
  category,
  description,
  readTime,
  publishDate,
  url,
  minimal,
}) => {
  const image = getImage(gatsbyImage)
  return (
    <CardContainer to={url}>
      <article className={`${minimal ? "minimal-card" : ""}`}>
        <GatsbyImage
          image={image}
          alt={title} /*style={{ overflow: "initial" }}*/
        />
        <section>
          <CardBody>
            {!minimal && (
              <CardTag>
                <span
                  style={{
                    textTransform: "uppercase",
                    background: "var(--clr-grey-9)",
                    marginRight: "0.5rem",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "var(--radius)",
                    letterSpacing: "2px",
                  }}
                >
                  {category}
                </span>
                {/* <span>Right</span> */}
              </CardTag>
            )}
            <header>
              <h3>{title}</h3>
            </header>
            <div>
              {!minimal && <CardDesc>{description}</CardDesc>}
              <CardFooter>
                <span>{publishDate} </span>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "var(--clr-grey-1)",
                  }}
                >
                  <Clock
                    size={15}
                    style={{
                      marginRight: "0.2rem",
                    }}
                  />
                  {readTime} minutes
                </span>
              </CardFooter>
            </div>
          </CardBody>
        </section>
      </article>
    </CardContainer>
  )
}

export default Card

const CardContainer = styled(Link)`
  text-decoration: none;
  display: block;
  margin-bottom: 2rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);

  &:hover {
    box-shadow: var(--dark-shadow);
    transform: scale(1.02);
  }

  // article {
  //   height: 100%;
  //   display: grid;
  //   grid-template-rows: auto 1fr;
  // }

  .minimal-card {
    @media (max-width: 640px) {
      flex-direction: row;
      .gatsby-image-wrapper {
        width: 200px;
      }
    }
  }

  article {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 1.17em;
    line-height: 1.25;
  }
`

const CardBody = styled.div`
  align-items: baseline;
  font-size: 0.875rem;
  color: var(--clr-grey-1);
  padding: 0.5rem 0.5rem;
`

const CardTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const CardDesc = styled.div`
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  //   white-space: nowrap;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.2rem; /* fallback */
  max-height: 2.5rem; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

const CardFooter = styled.div`
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
`
