import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/common/layout/Layout"
import Seo from "../components/common/SEO"
import ListPosts from "../components/sections/Posts"
import { Container, Section } from "../components/common/layout/Containers"
import SubscribeForm from "../components/sections/SubscribeForm"

const rootUrl = "/blog/"

const Page = ({ data }) => {
  const {
    allContentfulBlogPost: { nodes: posts },
    file,
  } = data

  const image = getImage(file.childImageSharp.gatsbyImageData)
  return (
    <Layout>
      <Seo title="Blog" description="Le voyage vers le mari de ta destinée" />
      <Section style={{ paddingTop: "0" }}>
        <GatsbyImage
          image={image}
          alt="vers le mari de ma destinée"
          style={{
            height: "15rem",
            width: "auto",
          }}
        />
        <Container>
          <div style={{ padding: "3rem 0" }}>
            <ListPosts posts={posts} rootUrl={rootUrl} />
          </div>
        </Container>
      </Section>
      <SubscribeForm bgLight />
    </Layout>
  )
}

export default Page

export const query = graphql`
  {
    allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
      nodes {
        id
        title
        slug
        description {
          description
        }
        body {
          body
        }
        publishDate(formatString: "DD MMMM YYYY", locale: "fr")
        categories
        heroImage {
          file {
            fileName
          }
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 2
            quality: 1
            resizingBehavior: FILL
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
        readTime
      }
      categories: distinct(field: categories)
    }
    file(
      sourceInstanceName: { eq: "book" }
      name: { eq: "marche-vers-le-mari-de-ta-destinee-2560x1440" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
          quality: 60
        )
      }
    }
  }
`
